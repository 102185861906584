<template>
<!-- 购物卡列表 -->
<div class="adh-card">
	<div class="el-content mb12">
		<a-space>
			<a-select v-model:value="search.fettle" style="width: 160px;">
				<a-select-option value="">激活状态</a-select-option>
				<a-select-option value="2">已激活</a-select-option>
				<a-select-option value="1">未激活</a-select-option>
			</a-select>
			<a-input v-model:value="search.key">
				<template #addonBefore>
					<a-select v-model:value="search.select_type" style="width: 120px">
						<a-select-option value="1">用户UID</a-select-option>
						<a-select-option value="2">手机号/昵称</a-select-option>
						<a-select-option value="3">卡号</a-select-option>
					</a-select>
				</template>
			</a-input>
			<a-range-picker v-model:value="search.time" />
			<a-button type="primary"  @click="getCardList(1,info.limit)">查询</a-button>
			<router-link to="/adhibition/card/card_edit">
				<a-button type="primary">添加礼品卡</a-button>
			</router-link>
			<a-button 
				@click="exportCard" 
				v-has="{action:'wxapp_marketing_card_export'}">导出所有未激活兑换卡
			</a-button>
		</a-space>
	</div>
	<div class="el-content">
		<a-table class="tb-overflow" :pagination="false" row-key="cid" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'cid',width:100},
			{title:'封面',dataIndex:'cover',slots:{customRender:'cover'},width:100},
			{title:'礼品卡标题',dataIndex:'title',width:200},
			{title:'礼品卡卡号',dataIndex:'number',width:200},
			{title:'密码',dataIndex:'password',width:110},
			{title:'状态',dataIndex:'fettle',slots:{customRender:'fettle'},width:100},
			{title:'面值',dataIndex:'price',width:100},
			{title:'用户',dataIndex:'user',slots:{customRender:'user'},width:200},
			{title:'兑换时间',dataIndex:'time',slots:{customRender:'time'},width:200},
			{title:'创建时间',dataIndex:'create_time',width:160},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'},width:100},
		]" :scroll="{x:300}">
			<template #cover="{record}">
				<div style="display: flex;align-items: center;">
					<a-image  style="width: 50px; height: 50px" :src="record.cover"/>
				</div>
			</template>
			<template #fettle="{record}">
				<a-tag :color="record.fettle == 2 ?'#00CC66' :'#999'">{{record.fettle == 2 ?'已激活':'未激活'}}</a-tag>
			</template>
			<template #user="{record}">
				<div v-if="record.user">
					<div>用户名：{{record.user.nickname}}</div>
					<div>电话：{{record.user.mobile}}</div>
					<div>userId：{{record.user.user_id}}</div>
				</div>
			</template>
			<template #time="{record}">
				{{record.start_time}}~{{record.end_time}}	
			</template>	
			<template #action="{record}">
				<a-space>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_marketing_card_del'}"  @click="deleteCard(record.cid)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getCardList(info.page,e)}"
				@change="(e)=>{getCardList(e,info.limit)}"
			/>
		</div>
	</div>
</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import cardModel from '@/api/addons/card'
import commonModel from '@/api/common'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			search:{
				time:[],
				key:"",
				fettle:'',
				select_type:"1"
			}
		})
		getCardList(1,state.info.limit)
		function getCardList(page,limit){
			cardModel.getCard(page,limit,state.search,res=>state.info = {limit,...res})
		}

		const deleteCard = (id)=>commonModel.deleteDataList(id,46,"确认要删除礼品卡信息吗？").then(()=>{
			getCardList(state.info.page,state.info.limit)
		})

		const exportCard = ()=>cardModel.exportCard(state.search)

		return{
			...toRefs(state),
			getCardList,
			deleteCard,
			exportCard
		}
	}
}
</script>

<style>
</style>
